<script setup>
import {Head, usePage} from '@inertiajs/vue3';
import Banner from '@/Components/Banner.vue';
import Sidebar from "@/Components/Sidebar.vue";
import Footer from "@/Components/Footer.vue";
import {useUser} from "@/Store/User.js";
import {route} from "ziggy-js";
import ReferralModal from "@/Components/ReferralModal.vue";
import {getLocalStorageItem} from "@/helper.js";
import {computed} from "vue";


defineProps({
    title: String,
});
const storeUser = useUser();
const page = usePage();

const theme = getLocalStorageItem('color-scheme');
if (theme) {
    storeUser.theme = theme;
}
const pageTheme = computed(() => {
    return route().current('referral') ? 'dark' : storeUser.theme
});

</script>
<template>
    <div :class="pageTheme"
         class="h-screen flex flex-col justify-between bg-gray-100">
        <Sidebar/>
        <Head :title="title"/>
        <Banner/>
        <div class="dark:bg-deep-blue overflow-y-auto grow flex flex-col" scroll-region>
            <div class="grow">
                <!-- Page Heading -->
                <header v-if="$slots.header" class="bg-white shadow">
                    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <slot name="header"/>
                    </div>
                </header>
                <!-- Page Content -->
                <main>
                    <div class="">
                        <slot/>
                    </div>
                </main>
            </div>
            <Footer class="grow-0"/>
        </div>
    </div>
    <ReferralModal/>
</template>
